import React, { useState } from "react";
import Select from "react-select";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";

import Icon from "../Icon/Icon";

import "./formElement.scss";

const FormElement = ({ children, ...props }) => {
    const isCheckbox = props.type === "checkbox";
    const isPassword = props.type === "password";
    const isSelect = props.type === "select";
    const isTextArea = props.type === "textarea";

    const isInvalid = props.invalidMsg
        ? {
            invalid: true,
        }
        : {
            valid: true,
        };

    // STATE
    const [passwordVisibility, setPasswordVisibility] = useState(false);

    const togglePasswordVisibility = () => {
        if (isPassword) {
            setPasswordVisibility(!passwordVisibility);
        }

        document.querySelector(`#${props.id}`).type = passwordVisibility
            ? "password"
            : "text";
    };

    const preparePropsForInput = (props) => {
        let newProps = { ...props };

        delete newProps.invalidMsg;
        delete newProps.msg;
        delete newProps.label;

        return newProps;
    };

    return !isCheckbox ? (
        <FormGroup className="form--group">
            <div className="form--input__container">
                {isPassword && !passwordVisibility && (
                    <Icon
                        asButton
                        className="icon-eye-off-min"
                        onClick={togglePasswordVisibility}
                    />
                )}
                {isPassword && passwordVisibility && (
                    <Icon
                        asButton
                        className="icon-eye-min"
                        onClick={togglePasswordVisibility}
                    />
                )}
                {isSelect ? (
                    <Select
                        classNamePrefix={`form--select`}
                        className={`${props.value.value ? "form--select__valid" : ""
                            }`}
                        name={props.name && props.name}
                        id={props.id && props.id}
                        placeholder={props.placeholder && props.placeholder}
                        options={props.options ?? []}
                        isSearchable={false}
                        {...isInvalid}
                        {...props}
                    />
                ) : isTextArea ? (
                    <Input
                        className="form--input"
                        type={props.type ?? "textarea"}
                        name={props.name && props.name}
                        id={props.id && props.id}
                        placeholder={props.placeholder && props.placeholder}
                        {...isInvalid}
                        {...preparePropsForInput(props)}
                    />
                ) : (
                    <Input
                        className="form--input"
                        type={props.type ?? "text"}
                        name={props.name && props.name}
                        id={props.id && props.id}
                        placeholder={props.placeholder && props.placeholder}
                        {...isInvalid}
                        {...preparePropsForInput(props)}
                    />
                )}

                {props.label && isSelect ? (
                    <Label
                        className={`form--label ${props.value.value ? "form--label__top" : ""
                            }`}
                        for={props.id}
                    >
                        {props.label}
                    </Label>
                ) : (
                    <Label
                        className={`form--label ${props.value ? "form--label__top" : ""
                            }`}
                        for={props.id}
                    >
                        {props.label}
                    </Label>
                )}
            </div>
            {props.invalidMsg && (
                <FormFeedback className="form--invalid-msg">
                    {props.invalidMsg}
                </FormFeedback>
            )}
        </FormGroup>
    ) : (
        <FormGroup className="form--group">
            <Input
                className="form--checkbox"
                {...props}
                checked={props.value}
            />
            <Label
                className="form--checkbox__label"
                for={props.id}
                dangerouslySetInnerHTML={{ __html: props.label }}
            ></Label>
        </FormGroup>
    );
};

export default FormElement;
